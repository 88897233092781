<template>
    <section id="cart-section">
    <div class="container">
        <h1 class="h3 mb-5">Wishlist Page</h1>
        <div class="row">
            <!-- Left -->
            <div class="col-lg-12 mb-10">
                <div class="card">
                    <div class="card-header">
                        <h2>Wishlist List</h2>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered m-0">
                                <thead>
                                <tr>
                                    <!-- Set columns width -->
                                    <th class="text-center py-3 px-4" style="min-width: 400px;">Product Name &amp; Details</th>
                                    <th class="text-right py-3 px-4" style="width: 100px;">Price</th>
                                    <th class="text-center align-middle py-3 px-0" style="width: 100px;">
                                        <a href="#" class="shop-tooltip float-none text-light" title="" data-original-title="Clear cart">
                                            <i class="ino ion-md-trash"></i>
                                        </a>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                <tr>
                                    <td class="p-4">
                                        <div class="media align-items-center" v-for="item in products" :key="item.product.id">
                                            <img class="equalHeightWidth" :src="showImage(item.product.thumbnail_img)" alt="Fila Exolize 2">
                                            <div class="media-body">
                                                <a href="#" class="d-block text-dark">{{ item.product.name }}</a>
                                                <small>
                                                    <span class="text-muted">Color:</span>
                                                    <span class="ui-product-color ui-product-color-sm align-text-bottom"
                                                          style="background:#e81e2c;"></span> &nbsp;
                                                    <span class="text-muted">Size: </span> EU 37 &nbsp;
                                                    <span class="text-muted">Ships from: </span> China
                                                </small>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="price">{{ $t("message.product_new.৳") }}
                                        {{
                                            item.product.priceType == 1 ? totalPrice(item.product.product_stock) : item.product.unit_price
                                        }}
                                    </td>
                                    <td class="text-center align-middle px-0">
                                        <a href="#" class="shop-tooltip close float-none text-danger" title="" data-original-title="Remove">
                                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                        </a>
                                    </td>
                                </tr>

                                <!-- <tr>
                                    <td class="p-4">
                                        <div class="media align-items-center">
                                            <img src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                                 class="d-block ui-w-40 ui-bordered mr-4" alt="">
                                            <div class="media-body">
                                                <a href="#" class="d-block text-dark">Product 2</a>
                                                <small>
                                                    <span class="text-muted">Color:</span>
                                                    <span class="ui-product-color ui-product-color-sm align-text-bottom"
                                                          style="background:#000;"></span> &nbsp;
                                                    <span class="text-muted">Storage: </span> 32GB &nbsp;
                                                    <span class="text-muted">Warranty: </span> Standard - 1 year &nbsp;
                                                    <span class="text-muted">Ships from: </span> China
                                                </small>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right font-weight-semibold align-middle p-4">$1049.00</td>
                                    <td class="text-center align-middle px-0">
                                        <a href="#" class="shop-tooltip close float-none text-danger" title="" data-original-title="Remove">
                                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="p-4">
                                        <div class="media align-items-center">
                                            <img src="https://bootdey.com/img/Content/avatar/avatar2.png"
                                                 class="d-block ui-w-40 ui-bordered mr-4" alt="">
                                            <div class="media-body">
                                                <a href="#" class="d-block text-dark">Product 3</a>
                                                <small>
                                                    <span class="text-muted">Ships from: </span> Germany
                                                </small>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right font-weight-semibold align-middle p-4">$20.55</td>
                                    <td class="text-center align-middle px-0">
                                        <a href="#"  class="shop-tooltip close float-none text-danger" title="" data-original-title="Remove">
                                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                        </a>
                                    </td>
                                </tr> -->

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import {debounce} from "lodash";
import {api_base_url} from "@/core/config/app";
import ApiService from "@/core/services/api.service";

export default {
  name: 'MyFavorite2',
  data() {
    return {
      products: []
    }
  },
  created() {
    this.loadProduct();
  },
  methods: {
    showImage(e) {
      return api_base_url + e;
    },
    loadProduct() {
        ApiService.get('user-product-favorites')
        .then(({data}) => {
            this.products = data;
        })
    },
    onFavorite(data) {
        ApiService.post('user-product-favorite/' + data.id)
        .then(() => {
            this.debounceName();
            toast.fire({
                icon: 'success',
                title: this.$t("message.common.success"),
            });
        }).catch(() => {
            swal.fire("Failed!", 'Error', 'warning')
        })
    },
    totalPrice: function (e) {
      if (e.length == 0) return;
      let max = e.reduce((a, b) => Number(a.price) > Number(b.price) ? a : b)
      let min = e.reduce((a, b) => Number(a.price) < Number(b.price) && 0 !== Number(a.price) ? a : b)
      return max === min ? max.price : min.price + ' - ' + max.price;
    }
  }
}
</script>

<style scoped>

</style>